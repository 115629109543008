import React, { Fragment } from 'react'
import Layout from '../../layout'
import { SEO, MainHeadings, Action, Table } from '../../components'

import DeveloperProgramOgImage from '../../assets/images/developer-program-seo-image.png'

const Offer = () => {
  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Developer Program Offer"
        description="Build communication tools with us. Join LiveChat Developer Program to grow your business and build new revenue streams."
        image={DeveloperProgramOgImage}
      />
      <div className="u-bg-black3 u-Mb-md md:u-Mb-xl">
        <MainHeadings
          vertical
          title={
            <Fragment>
              Developer Program <span className="u-text-mark">Offer</span>
            </Fragment>
          }
          subtitle="Build next-level communication tools with us. LiveChat Developer Program offers support in three areas: research, development, and distribution."
        />
      </div>
      <Table />
      <div className="u-bg-black2 u-Py-md">
        <Action
          image="justynaJanowska"
          title={
            <>
              The reach of Platform is what <br />
              made it click for our app.
            </>
          }
          subtitle={
            <Fragment>
              <b>Justyna Janowska</b>, UX Designer at KnowledgeBase.ai
            </Fragment>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: 'Join Developer Program',
          }}
          link={{
            link: `${
              process.env.GATSBY_DOCS_URL
            }extending-ui/extending-agent-app/`,
            label: 'browse the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default Offer
